import React, { useState } from "react";
import Footer from "./Footer";
import bg from "../welcome-bg.jpg";
import HeaderBackend from "./HeaderBackend";
import { useParams } from "react-router-dom";
import { getToken } from "../utils/LocalStorage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminViewMore = () => {

  const toastOptions = {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  };

  const [conference, setConference] = useState([]);
  const [organizer, setOrganizer] = useState([]);

  const { id } = useParams();

  // const base = "http://localhost:1000";
  const base = 'https://confchair-backend.vercel.app';

  // Conference Details
  const host = `${base}/api/v1/conference/${id}`;
  React.useEffect(() => {
    fetch(host, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      setConference(data.message);
    });
  }, [host]);

  // Organizer Details
  const organizerHost = `${base}/api/v1/auth/get/id/${conference.organizer}`;
  React.useEffect(() => {
    fetch(organizerHost, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: getToken(),
      },
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      setOrganizer(data.message);
    });
  }, [conference.organizer]);

  // Organizer Details
  const handleStatus = async (e) => {
    e.preventDefault();

    console.log(e.target.status.value);

    try {
      const response = await fetch(`${base}/api/v1/conference/update/status/${id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `${getToken()}`,
          },
          body: JSON.stringify({
            status: e.target.status.value,
          }),
        }
      );
      const data = await response.json();
      if (data.status === 'failed') {
        toast.error(data.message, toastOptions);
      } else {
        toast.success(data.message, toastOptions);
        setTimeout(() => {
          window.location.href = `/admin-view`
        }, 2500);
      }
    } catch (error) {
      toast.error(error.message, toastOptions);
      console.error(error);
    }
  };

  return (
    <div>
      <ToastContainer />
      <section
        id="welcome"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <HeaderBackend />
        <div className="container position-relative pt-5" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <h2>Conference Id -: 139</h2>
              <p>
                Sed nam ut dolor qui repellendus iusto odit. Possimus inventore
                eveniet accusamus error amet eius aut accusantium et. Non odit
                consequatur repudiandae sequi ea odio molestiae. Enim possimus
                sunt inventore in est ut optio sequi unde.
              </p>
            </div>
            <div className="col-lg-3">
              <h3>Where</h3>
              <p>Downtown Conference Center, New York</p>
            </div>
            <div className="col-lg-3">
              <h3>When</h3>
              <p>
                Monday to Wednesday
                <br />
                10-12 December
              </p>
            </div>
          </div>
        </div>
      </section>
      <h2 className="text-center pt-4 mt-4">Organizer</h2>
      <div
        className="table-responsive table-scroll px-5 mx-5"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "150px" }}
      >
        <table className="table table-striped mb-0">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-2">
                First Name
              </th>
              <th scope="col" className="col-md-2">
                Last Name
              </th>
              <th scope="col" className="col-md-3">
                Email
              </th>
              <th scope="col" className="col-md-1">
                Affiliation
              </th>
              <th scope="col" className="col-md-3">
                Contact
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="schedule-item">
              <td>{organizer.firstName}</td>
              <td>{organizer.lastName}</td>
              <td>{organizer.email}</td>
              <td>{organizer.organizationName}</td>
              <td>{organizer.mobileNumber}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="information table-responsive table-scroll px-5 mx-5 pb-1 mb-1"
        data-mdb-perfect-scrollbar="true"
      >
        <h2 className="text-center">Conference Details</h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <td scope="row">{conference.type}</td>
            </tr>
            <tr>
              <th scope="col">Conference Title</th>
              <td scope="row">{conference.conferenceTitle}</td>
            </tr>
            <tr>
              <th scope="col">Acronym</th>
              <td scope="row">{conference.acronym}</td>
            </tr>

            <tr>
              <th scope="col">Web Page</th>
              <td scope="row">
                <a href={`${conference.webPage}`} className="table-url">
                  <img
                    width="24"
                    height="24"
                    src="https://img.icons8.com/fluency/48/link.png"
                    alt="link"
                  />
                </a>
              </td>
            </tr>
            <tr>
              <th scope="col">Mode</th>
              <td scope="row">{conference.modeOfConference}</td>
            </tr>
            <tr>
              <th scope="col">Venue</th>
              <td scope="row">{conference.venue}</td>
            </tr>
            <tr>
              <th scope="col">City</th>
              <td scope="row">{conference.city}</td>
            </tr>
            <tr>
              <th scope="col">Country/Region</th>
              <td scope="row">{conference.country}</td>
            </tr>
            <tr>
              <th scope="col">Estimated no. of Submissions</th>
              <td scope="row">{conference.estimatedNoOfSubmission}</td>
            </tr>
            <tr>
              <th scope="col" className="bg-table">
                Start Date
              </th>
              <td scope="row">{conference.startDate}</td>
            </tr>
            <tr>
              <th scope="col">End Date</th>
              <td scope="row">{conference.endDate}</td>
            </tr>
            <tr>
              <th scope="col">Primary Area</th>
              <td scope="row">{conference.primaryArea}</td>
            </tr>
            <tr>
              <th scope="col">Secondary Area</th>
              <td scope="row">{conference.secondaryArea}</td>
            </tr>
            <tr>
              <th scope="col">Area Notes</th>
              <td scope="row">{conference.areaNotes}</td>
            </tr>
            <tr>
              <th scope="col">Role</th>
              <td scope="row">{conference.yourRole}</td>
            </tr>
            <tr>
              <th scope="col">Any other information</th>
              <td scope="row">{conference.otherInformation}</td>
            </tr>
          </thead>
        </table>
      </div>
      <div className="flex text-center px-5 mx-5 py-3 my-3 pb-5 mb-5">
        <form onSubmit={handleStatus}>
          <h2 className="text-center">Update Status</h2>
          <div className="d-flex flex-row">
            <div className="col-4">
              <select name="status" className="form-select">
                <option value="approve" className="">
                  Accept
                </option>
                <option value="reject" className="">
                  Reject
                </option>
                <option value="underReview" className="">
                  Under Review
                </option>
                <option value="underReview" className="">
                  Send Reminder
                </option>
              </select>
            </div>
            <div className="col-1">
              <button className="btn btn-block btn-custom" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default AdminViewMore;
