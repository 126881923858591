import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Welcome from "./Welcome";
import Footer from "./Footer";

const AdminView = () => {

  const navigate = useNavigate();

  const [conferences, setConferences] = useState([]);

  // const base = 'http://localhost:1000';
  const base = 'https://confchair-backend.vercel.app';

  const host = `${base}/api/v1/conference/all`;
  React.useEffect(() => {
    fetch(host, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    }).then(async (res) => {
      const data = await res.json();
      console.log(data);
      setConferences(data.message);
    });
  }, [host]);

  return (
    <div>
      <Welcome />
      <div className="section-header text-center justify-content-center pt-4 mt-4">
        <h2>Conference Requests</h2>
        <p>Here is the list of all Conference Requests</p>
      </div>
      <div
        className="table-responsive table-scroll px-4 mx-4"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        <table className="table table-striped mb-0 table-bordered">
          <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
            <tr>
              <th scope="col" className="col-md-1">
                Type
              </th>
              <th scope="col" className="col-md-3">
                Conference Title
              </th>
              <th scope="col" className="col-md-1">
                Acronym
              </th>
              <th scope="col" className="col-md-2">
                Mode
              </th>
              <th scope="col" className="col-md-2">
                Start Date
              </th>
              <th scope="col" className="col-md-0.5 writing-mode">
                Web Page
              </th>
              <th scope="col" className="col-md-0.5 writing-mode">
                Information
              </th>
              <th scope="col" className="col-md-2">
                Status
              </th>
            </tr>
          </thead>
          {conferences.map((data, index) => {
            return <tbody key={index}>
              <tr className="schedule-item">
                <td>{data.type}</td>
                <td>{data.conferenceTitle}</td>
                <td>{data.acronym}</td>
                <td>{data.modeOfConference}</td>
                <td>{data.startDate}</td>
                <td>
                  <a
                    href={`${data.webPage}`}
                    className="table-url"
                    target="_blank"
                  >
                    <img
                      width="24"
                      height="24"
                      src="https://img.icons8.com/fluency/48/link.png"
                      alt="link"
                    />
                  </a>
                </td>
                <td>
                  <Link to={`/admin-view-more/${data._id}`}>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-filled/50/info.png"
                      alt="info"
                    />
                  </Link>
                </td>

                <td>{data.status}</td>
              </tr>
            </tbody>
          })
          }
        </table>
      </div>
      <Footer />
    </div>
  );
};

export default AdminView;
