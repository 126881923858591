import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../hero-bg.jpg";
import Footer from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import { storeToken } from "../utils/LocalStorage";
import HeaderLogin from "./HeaderLogin";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const base = 'https://confchair-backend.vercel.app';
  // const base = "http://localhost:1000";

  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const spinner = (
    <PulseLoader
      color="black"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements;

    setIsLoading(true);

    try {
      const response = await fetch(`${base}/api/v1/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
        }),
      });

      const data = await response.json();

      if (data.status === "failed") {
        toast.error(data.message, toastOptions);
      } else {
        toast.success(data.message, toastOptions);
        storeToken(data.token, 432000);
        setTimeout(() => {
          window.location.href = "/admin-view";
        }, 2500);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred", toastOptions);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        className="vh-100"
        style={{ backgroundImage: `url(${bg})` }}
        id="hero1"
      >
        <HeaderLogin />
        <section className="pt-5 mt-3">
          <div className="container h-25">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div
                  className="card shadow-2-strong"
                  style={{ borderRadius: "1rem" }}
                >
                  <form onSubmit={handleLogin} className="card-body p-5">
                    <h3 className="mb-3 text-center">Login as Admin ConfChair</h3>
                    <div className="form-outline mb-4">
                      <label className="form-label required">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="typeEmailX-2"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label required">Password</label>
                      <input
                        type="password"
                        name="password"
                        id="typePasswordX-2"
                        className="form-control form-control-lg"
                      />
                    </div>

                    <div className="text-center text-lg-start mt-2">
                      <div className="mb-2">
                        <button
                          type="sumbit"
                          className="btn btn-custom text-white btn-lg"
                          style={{
                            paddingLeft: "2.5rem",
                            paddingRight: "2.5rem",
                          }}
                        >
                          {isLoading ? spinner : "Login"}
                        </button>
                      </div>
                      <div className="mb-2">
                        <p>
                          Don't have an account?{" "}
                          <a href="/register" className="a-link">
                            Register Now
                          </a>
                        </p>
                      </div>
                      <div className="mb-2">
                        <a href="/forget-password" className="a-link">
                          Forgot Password ?
                        </a>
                      </div>
                    </div>

                    {/* <hr className="my-4" />

                    <button
                      className="btn btn-lg btn-block btn-primary"
                      style={{ backgroundColor: "#dd4b39" }}
                      type="submit"
                    >
                      <i className="fab fa-google me-2"></i> Sign in with google
                    </button>
                    <button
                      className="btn btn-lg btn-block btn-primary mb-2"
                      style={{ backgroundColor: "#3b5998" }}
                      type="submit"
                    >
                      <i className="fab fa-facebook-f me-2"></i>Sign in with
                      facebook
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
