import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo1.png";
import User from "../images/User.svg";

const HeaderBackend = () => {
  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    // <div>
    //   <header id="header-backend" className="d-flex align-items-center ">
    //     <div className="container-fluid container-xxl d-flex align-items-center">
    //       <div id="logo" className="me-auto mt-2">
    //         {/* <h1>
    //           <Link to="/">
    //             CONF<span>CHAIR</span>
    //           </Link>
    //         </h1> */}
    //         <Link to="/" className="scrollto">
    //           <img src={logo} alt="CONF CHAIR" />
    //         </Link>
    //       </div>

    //       <nav
    //         id="navbar"
    //         className="navbar order-last order-lg-0 navbar-backend"
    //       >
    //         <ul>
    //           <li className="dropdown">
    //             <a href="#">
    //               <span>Confrences</span> <i className="bi bi-chevron-down"></i>
    //             </a>
    //             <ul>
    //               <li>
    //                 <Link to="/recent-roles">Recent Roles</Link>
    //               </li>
    //               <li>
    //                 <Link to="/organizer-view">Manage Confrences</Link>
    //               </li>
    //               <li>
    //                 <Link to="/welcome">All Confrences</Link>
    //               </li>
    //             </ul>
    //           </li>
    //           <li>
    //             <a className="nav-link scrollto" href="#about">
    //               CFP
    //             </a>
    //           </li>
    //         </ul>
    //         <i className="bi bi-list mobile-nav-toggle"></i>
    //       </nav>
    //       <Link
    //         className="nav-link link-button scrollto"
    //         to="/create-conference"
    //       >
    //         <button className="btn nav-btn-left nav-btn-mobile btn-log-backend scrollto">
    //           Create a Confrence
    //         </button>
    //       </Link>
    //       <button
    //         onClick={handleLogout}
    //         className="btn btn-log-backend scrollto nav-btn nav-btn-mobile"
    //       >
    //         Log Out
    //       </button>
    //     </div>
    //   </header>
    // </div>
    <div>
      <header id="header-backend" className="d-flex align-items-center">
        <div className="container-fluid container-xxl d-flex align-items-center">
          <div id="logo" className="me-auto mt-2">
            <Link to="/" className="scrollto">
              <img src={logo} alt="CONF CHAIR" />
            </Link>
          </div>

          <nav
            id="navbar"
            className="navbar order-last order-lg-0 navbar-backend"
          >
            <ul>
              <Link className="nav-link link-button scrollto" to="/conferences">
                <button className="btn btn-log scrollto nav-btn nav-btn-mobile">
                  Conferences
                </button>
              </Link>
            </ul>
            <ul>
              <Link className="nav-link link-button scrollto" to="/admin-view">
                <button className="btn btn-log scrollto nav-btn nav-btn-mobile">
                  Requests
                </button>
              </Link>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
          <div className="dropdown">
            <a
              className="nav-link link-button scrollto"
              href="#"
              role="button"
              id="profileDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {/* <img
            src={User}
            alt="Profile"
            className="profile-image"
          /> */}
              <img
                width="36"
                height="36"
                className="profile-image"
                src="https://img.icons8.com/color/48/test-account.png"
                alt="test-account"
              />
            </a>
            <ul className="dropdown-menu" aria-labelledby="profileDropdown">
              <li>
                <Link className="dropdown-item" to="/my-account">
                  My Account
                </Link>
              </li>
              {/* <li>
                <Link className="dropdown-item" to="/settings">
                  Settings
                </Link>
              </li> */}
              <li>
                <button
                  onClick={handleLogout}
                  className="dropdown-item"
                  type="button"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeaderBackend;
