import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllConferences = () => {
  const toastOptions = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const [conferences, setConferences] = useState([]);

  const [conferencesStatus, setConferencesStatus] = useState([]);

  // const base = "http://localhost:1000";
  const base = "https://confchair-backend.vercel.app";

  const host = `${base}/api/v1/conference/all`;
  React.useEffect(() => {
    fetch(host, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (res) => {
      const data = await res.json();
      // console.log(data);
      setConferences(data.message);
      setConferencesStatus(data.status);
    });
  }, [host]);

  const notAccepted = () => {
    toast.error(
      "Currently Submission not Accepted. Please try to contact to Organizer",
      toastOptions
    );
  };
  return (
    <div>
      <div
        className="table-responsive table-scroll"
        data-mdb-perfect-scrollbar="true"
        style={{ position: "relative", height: "700px" }}
      >
        {conferencesStatus === "failed" ? (
          <>
            <table className="table table-striped mb-0 table-bordered">
              <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
                <tr>
                  <th scope="col" className="col-md-4">
                    Conference Name
                  </th>
                  <th scope="col" className="col-md-1">
                    Start Date
                  </th>
                  <th scope="col" className="col-md-1">
                    End Date
                  </th>
                  <th scope="col" className="col-md-2">
                    Country
                  </th>
                  <th scope="col" className="col-md-1 writing-mode">
                    Submissions
                  </th>
                  <th scope="col" className="col-md-1 writing-mode">
                    Accepted Papers
                  </th>
                  <th scope="col" className="col-md-1 writing-mode">
                    Registered
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Information
                  </th>
                  <th scope="col" className="col-md-0.5 writing-mode">
                    Web Page
                  </th>
                </tr>
              </thead>
            </table>
            <h3 className="text-center my-2">No Conferences</h3>
          </>
        ) : (
          <table className="table table-striped mb-0 table-bordered">
            <thead style={{ backgroundColor: "#0e1b4d", color: "#fff" }}>
              <tr>
                <th scope="col" className="col-md-4">
                  Conference Name
                </th>
                <th scope="col" className="col-md-1">
                  Start Date
                </th>
                <th scope="col" className="col-md-1">
                  End Date
                </th>
                <th scope="col" className="col-md-2">
                  Country
                </th>
                <th scope="col" className="col-md-1 writing-mode">
                  Submissions
                </th>
                <th scope="col" className="col-md-1 writing-mode">
                  Accepted Papers
                </th>
                <th scope="col" className="col-md-1 writing-mode">
                  Registered
                </th>
                <th scope="col" className="col-md-0.5 writing-mode">
                  Information
                </th>
                <th scope="col" className="col-md-0.5 writing-mode">
                  Web Page
                </th>
              </tr>
            </thead>
            {conferences.map((data, index) => {
              return (
                <tbody key={index}>
                  <tr className="schedule-item">
                    <td>
                      {data.acceptSubmissions ? (
                        <Link to={`/admin-view-more/${data._id}`}>
                          {data.conferenceTitle}
                        </Link>
                      ) : (
                        <Link to="#" onClick={notAccepted}>
                          {data.conferenceTitle}
                        </Link>
                      )}
                    </td>
                    <td>{data.startDate}</td>
                    <td>{data.endDate}</td>
                    <td>
                      {data.city}, {data.country}
                    </td>

                    <td>100</td>
                    <td>70</td>
                    <td>60</td>
                    <td>
                      <Link to={`/admin-view-more/${data._id}`}>
                        <img
                          width="20"
                          height="20"
                          src="https://img.icons8.com/ios-filled/50/info.png"
                          alt="info"
                        />
                      </Link>
                    </td>
                    <td>
                      <a
                        href={`${data.webPage}`}
                        className="table-url"
                        target="_blank"
                      >
                        <img
                          width="24"
                          height="24"
                          src="https://img.icons8.com/fluency/48/link.png"
                          alt="link"
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        )}
      </div>
    </div>
  );
};

export default AllConferences;
