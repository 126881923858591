import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminView from "./components/AdminView";
import AdminViewMore from "./components/AdminViewMore";
import Login from "./components/Login";
import AllConferences from "./components/AllConferences";
import AcceptedConferences from "./components/AcceptedConferences";
import Tab from "./components/Tab";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin-view" element={<AdminView />} />
        <Route path="/admin-view-more/:id" element={<AdminViewMore />} />
        <Route path="/conferences" element={<Tab />} />
      </Routes>
    </div>
  );
};

export default App;
